/* ProfilePage.css */

/* General styling for the page */
.container {
    max-width: 900px;
    margin-top: 50px;
  }
  
  /* Styling for the profile card */
  .card {
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
  }
  
  /* Header style */
  .card-header {
    background-color: #007bff;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  /* Body of the card */
  .card-body {
    padding: 20px;
  }
  
  /* Styling the profile image */
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #007bff;
    margin-bottom: 20px;
  }
  
  /* Profile info text */
  .card-text {
    font-size: 16px;
    color: #555;
  }
  
  /* Edit Button */
  .button-edit {
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button-edit:hover {
    background-color: #0056b3;
  }
  
  /* Form styling for edit mode */
  .form-control {
    border-radius: 5px;
    padding: 10px;
  }
  
  /* Save Changes button */
  .button-save {
    margin-top: 15px;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button-save:hover {
    background-color: #218838;
  }
  
  .card-footer {
    background-color: #f8f9fa;
    padding: 10px;
    text-align: center;
    color: #777;
  }
  