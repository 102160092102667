/* ShopItems.css */

/* General Container */
.shop-container {
    padding: 15px;
}

/* Shop Header */
.shop-header {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #2c3e50;
   
    letter-spacing: 2px;
    margin-bottom: 40px;
    font-size: 32px;
}

/* Card Container */
.shop-items-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  /* Center all the cards */
    gap: 20px;
}

/* Shop Card Styling */
.shop-card {
    border-radius: 12px;
    overflow: hidden;
    border: none;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
    height: 100%; /* Allow card to stretch */
    display: flex;
    flex-direction: column; /* Ensure all content stacks vertically */
    /* Set a minimum height for cards to ensure uniformity */
    min-height: 380px;
}

.shop-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

.shop-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #eee;
}

/* Title and Description */
.shop-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #333;
    font-size: 20px;
    margin-top: 15px;
}

.shop-description {
    font-size: 14px;
    color: #7f8c8d;
    margin-bottom: 15px;
    flex-grow: 1; /* Ensures that the description takes up all available space */
}

/* Products List */
.shop-products {
    padding-left: 20px;
    list-style-type: disc;
}

.product-item {
    font-size: 14px;
    color: #34495e;
    margin-bottom: 5px;
}

/* Button Style */
.visit-btn {
    width: 100%;
    background-color: #3498db;
    border-color: #3498db;
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
    border-radius: 25px;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: auto; /* Push the button to the bottom of the card */
}

.visit-btn:hover {
    background-color: #2980b9;
    border-color: #2980b9;
    transform: scale(1.05);
}

/* Mobile Responsiveness */
@media (max-width: 767px) {
    .shop-card {
        margin-bottom: 30px;
    }

    .shop-title {
        font-size: 18px;
    }

    .visit-btn {
        font-size: 14px;
    }
}
