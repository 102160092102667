/* Slick Carousel Reset */
.slick-track {
    margin: 0 !important;
    padding: 0 !important;
}

.slick-slide {
    margin: 0 !important;
    padding: 2px !important;
}

.slick-prev:before,
.slick-next:before {
    content: "";
    display: inline-block;
    width: 48px;
    height: 48px;
}

/* Container adjustments */
@media (min-width: 1101px) {
    .slick-track {
        margin: 0 auto; /* Center the track in the container */
    }

    .testimonial-section {
        padding: 40px 0; /* Adjust padding here */
    }

    .container {
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.testimonial-section {
    position: relative;
    overflow: hidden;
}

.slick-slide {
    padding: 0 10px; /* Adjust padding to reduce unwanted space */
}

.testimonial-heading {
    text-align: center;
    font-size: 2.5rem; /* Default size for larger screens */
    margin-bottom: 50px;
    font-weight: 700;
    font-family: 'Protest Revolution', sans-serif;
    color: #000;
    margin-top: -5rem;
}

@media (max-width: 600px) {
    .testimonial-heading {
        font-size: 2rem;
        margin-bottom: 30px;
    }
}

.testimonial-card {
    position: relative;
    text-align: center;
    padding: 20px;
    background-color: rgba(2, 44, 68, 0.8);
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.testimonial-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.testimonial-text {
    padding: 20px;
}

.testimonial-text p {
    font-style: italic;
}

.testimonial-text h5 {
    font-weight: bold;
}

.location {
    color: #d4caca;
}

/* Slider navigation adjustments */
.slick-prev:before {
    margin-left: -45px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg>');
}

.slick-next:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>');
}

@media (max-width: 768px) {
    .slick-prev:before {
        margin-left: -10px !important;
    }
    .slick-next:before {
        margin-left: -10px !important;
    }
}
